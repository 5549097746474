// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

// Import Site Components
import CVHeader from './cvHeader';

// Import Additional Assets
import NTT from '../assets/ntt-verio.svg';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#304f8e',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url("${NTT}")`,
    height: '200px',
    width: '200px',
    margin: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#fff',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff'
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff',
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: '#fff',
  }
}));

export default function VerioPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CVHeader />
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              NTT/Verio
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              Web Hosting Made Simple
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              UNIX/Linux Systems Administrator
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              2003 - 2007 (4 years)
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText}>
            Ah the halcyon days of the post-dot com era, where everybody had either a hotmail, Yahoo!, or AOL email address, IRC was where all the L337 kids congregated, FreeBSD UNIX reigned King, and <em>this year</em> was going to be the year of the Linux desktop. When "eCommerce" was still new and your online business presence lived or died on the reliability of your web hosting provider. And when it came web hosting, Verio was top dog.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            If I wax nostalgic about this era it's because this is where my career path as a Systems Engineer ( <em>and, admittedly, a BOFH</em> ) began. I was a young twentysomething, freshly minted System Admin and a lot of the core principals of Systems Administration and Engineering I learned early on from my days at Verio. Everything from Data Center operations, to backup and disaster recovery, to the fundamentals of multitenancy server clustering I learned during my time here. Over the course of my day-to-day I was responsible for managing and maintaining the Irix, FreeBSD, and RHEL infrastructure for a Production server farm of more than 5,000 systems distributed worldwide in data centers across the US, UK, Germany, and Japan.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            While the world has moved on and the digital landscape has certainly changed, my time working here is always near and dear to my heart.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}