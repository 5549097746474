// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const Styles = makeStyles({
  root: {
    width: '200px',
    margin: '20px',
    borderRadius: '50%',
    boxShadow: 'none',
  },
  media: {
    height: '200px',
    width: '200px',
    backgroundPosition: 'center',
  },
  title: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '2rem',
  },
  desc: {
      fontFamily: 'Lato',
      fontSize: '1.1rem',
  }
});

export default function CVCard({ page }) {
  const classes = Styles();

  return (
        <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
            className={classes.media}
            image={page.image}
            title={page.title}
            />
            </CardActionArea>
        </Card>
  );
}