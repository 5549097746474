// Import React Components
import React from 'react';
import { Link as Scroll } from 'react-scroll';

// Import MUI Elements
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';

// Import Additional Assets
import BGimage from '../assets/ban0.jpg';

const Styles = makeStyles((theme) => ({
    hero: {
      backgroundImage: `url(${BGimage})`,
      height: '500px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: 300,
        fontSize: "3em",
      }
    },
    root: {
        height: '100vh'
    },
    blurbContainer: {
      paddingTop: theme.spacing(3)
    },
    blurbTitle: {
      fontWeight: 800,
      fontFamily: 'Montserrat',
    },
    blurbSubtitle: {
      fontWeight: 300,
      fontFamily: 'Lato',
      fontStyle: 'italic',
      paddingBottom: theme.spacing(3)
    },
    blurbBody: {
      fontWeight: 300,
      fontFamily: 'Lato',
      paddingTop: theme.spacing(3)
    },
    navButton: {
      paddingTop: theme.spacing(5),
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    socialButton: {
      position: 'relative',
      flexDirection: 'row-reverse'     
    },
    socialbuffer: {
      paddingBottom: theme.spacing(3),
    },
    twitterButton: {
      color: '#00acee',
     
    },
    linkedin: {
      color: '#2867B2',
     
    },
    gitHub: {
      color: '#6e5494',
     
    },
    email: {
      color: '#ffc0cb',
    },
  }))
  
  export default function mainPage() {
    const classes = Styles();
    // const [checked, setChecked] = UseState(false);

    // const handleChange = () => {
    //     setChecked((prev) => !prev);
    // };
  
    return (
      <div className={classes.root} id="mainPage">
          <Box className={classes.hero} />
        <Container maxWidth="lg" className={classes.blurbContainer}>
            <Grid container spacing={3} direction="row">
              <Grid item>
                <Typography variant="h4" align="left" className={classes.blurbTitle}>
                  k8mnstr.com <ArrowForwardIosIcon />
                </Typography>
                <Typography variant="subtitle2" align="left" className={classes.blurbSubtitle}>
                  Variously credentialed, generally ambivalent.
                </Typography>
              </Grid>
            </Grid>
          <Divider />
            <Grid container spacing={3} direction="row">
              <Grid item>
                <Typography variant="body1" align="left" color="textPrimary" component="p" className={classes.blurbBody}>
                Hello, World!
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" className={classes.socialbuffer}>
              <Grid item>
                <Typography variant="body1" align="left" color="textPrimary" component="p">
                My name is Katelynn Cusanelli and this is my personal website. Some people may recognize me as a public figure from my time spent as a television personality on MTV or as an acclaimed civil rights activist, but I'm best known professionally for my work as a Linux Systems Engineer, consultant, and Public Speaker. I have worked with many organizations nationwide including GLAAD, HRC, and the Trevor Project doing community outreach, technical or diversity training, and giving keynote lectures.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="left" color="textPrimary" component="p">
                My goal for this site is to serve as a primary index for all things me, and because I have a passion for civil rights, technology, and writing. Please see my sections below for more information about who I am and the work that I do.
                </Typography>
              </Grid>
            </Grid>
          {/* </Fade> */}
          {/* <Collapse in={checked} {...(checked ? { timeout: 2000 } : {})} */}
            <Divider />
            <Grid container direction="row" align="right" className={classes.socialButton}>
              <Grid item>
                <IconButton href="https://twitter.com/k8mnstr" target="_blank" title="Follow me on Twitter!" className={classes.twitterButton}>
                  <TwitterIcon fontsize="large"/>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton href="https://www.linkedin.com/in/k8mnstr/" target="_blank" title="Check me out on LinkedIn" className={classes.linkedin}>
                  <LinkedInIcon fontsize="large"/>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton href="https://github.com/k8mnstr" target="_blank" title="My (admittedly, barren) GitHub repos" className={classes.gitHub}>
                  <GitHubIcon fontsize="large"/>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton href="mailto:XiaoKatelynn@gmail.com" target="_blank" title="Complaints go to /dev/null" className={classes.email}>
                  <EmailIcon fontsize="large"/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" align="center" className={classes.navButton}>
              <Scroll to="siteSections" smooth={true}><IconButton>
                <ExpandMoreIcon fontSize="large"/>
              </IconButton></Scroll>
            </Grid>
          {/* </Collapse> */}
        </Container>
      </div>
    );
  }