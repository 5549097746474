// Import React Components
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';

// Import Site Components

// Import Pages for RouterDom
import HomePage from './HomePage'
import AboutPage from './About';
import CVPage from './CV';
import CLDRPage from './components/cldrPage';
import FFNPage from './components/ffnPage';
import TaosPage from './components/taosPage';
import FlutterPage from './components/flutterPage';
import TivoPage from './components/tivoPage';
import IntelPage from './components/intelPage';
import VerioPage from './components/verioPage';
import AttributionPage from './components/attributions';
import ChangePage from './components/changelog';
import SkillsPage from './Skills';

// Import Additional Assets
import '@fontsource/montserrat';
import '@fontsource/lato';

const Styles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
}));

export default function App() {
  const classes = Styles();
  return (
    <Router>
      <div className={classes.root}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/about" exact component={AboutPage} />
          <Route path="/attr" exact component={AttributionPage} />
          <Route path="/changelog" exact component={ChangePage} />
          <Route path="/cv" exact component={CVPage} />
          <Route path="/cv/cldr" exact component={CLDRPage} />
          <Route path="/cv/ffn" exact component={FFNPage} />
          <Route path="/cv/taos" exact component={TaosPage} />
          <Route path="/cv/flutter" exact component={FlutterPage} />
          <Route path="/cv/tivo" exact component={TivoPage} />
          <Route path="/cv/intel" exact component={IntelPage} />
          <Route path="/cv/verio" exact component={VerioPage} />
          <Route path="/skills" exact component={SkillsPage} />
        </Switch>
      </div>
    </Router>
  );
}