import {createMuiTheme} from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#000000',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ff0000',
            contrastText: '#ffffff'
        },
        background: {
            default: '#ffffff',
        },
        divider: '#C0C0C0'
    },
    typography: {
        fontFamily: 'Lato',
        htmlFontSize: 16,
        h1: {
            fontFamily: 'Montserrat',
            fontWeight: 700
        }
    }
})

export default defaultTheme