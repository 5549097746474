// Import React Components
import React from 'react';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Import Site Components

// Import Additional Assets
import Taos from '../assets/taos.png';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '##fff',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url(${Taos})`,
    height: '200px',
    width: '200px',
    margin: '20px',
    borderRadius: '0%',
    boxShadow: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#5c3260',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#5c3260',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#4d4d4e',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#4d4d4e',
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#4d4d4e',
    paddingTop: theme.spacing(1),
  },
  divider: {
    background: '#5c3260',
  },
  appbar: {
    background: 'none',
    fontFamily: 'Montserrat'
  },
  appbarTitle: {
      flexGrow: '1',
  },
  appbarWrapper: {
      width: '-100%',
      margin: '0 auto',
  },
  icon: {
      color: "#5c3260",
      fontSize: '2rem',
  },
}));

export default function TaosPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} elevation={0}>
        <Toolbar className={classes.appbarTitle}>
        <Scroll to="mainPage" smooth={true}><Link to="/cv"><IconButton>
                <CloseIcon className={classes.icon}/>
            </IconButton></Link></Scroll>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              Taos Consulting
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              Solutions for any challenge, support every step of the way.
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              Linux Systems Administrator
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              2013 - 2015 (2 years)
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText}>
            Taos is one of the Bay Area's premier consulting agencies. As a Linux System Admin I was able to help our clients fill the technical gaps in their team to help them with whatever they needed. For some companies, like Ericsson, this meant updating their infrastructure with Jenkins for continuous build integration and automating system configurations with Puppet. Other companies, like Guidewire, needed help scaling up their internal Development and UAT clusters to meet their growing business demands. Some clients needed shorter engagements to help them with specific solutions to meet their unique use cases.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            Whether it was Site Reliability Engineering, Solutions Architecture, or good 'ol fashioned Systems Administration, through Taos I was able to help many companies in the Bay Area achieve success with their projects.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}