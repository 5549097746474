// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

// Import Site Components
import CVHeader from './cvHeader';

// Import Additional Assets
import FFN from '../assets/ffn-new.png';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ef432b',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url(${FFN})`,
    height: '200px',
    width: '200px',
    margin: '20px',
    borderRadius: '0%',
    boxShadow: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#fff',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff'
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff',
    paddingTop: theme.spacing(1),
  },
  divider: {
    background: '#fff',
  },
  linkText: {
    fontWeight: 700,
    color: '#fff',
    textDecoration: 'none',
  },
}));

export default function FFNPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CVHeader />
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              FriendFinder Networks
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              Because the Internet really *is* for pr0n.
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              Senior Linux Systems Administrator
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              {' 2015 (> 1 year)' }
            </Typography>
            <Typography variant="body1" align="justify" component="p" className={classes.cvText}>
            FriendFinder is one of the Internet's oldest websites, and one of the rare few survivors of the dot com bust of the late 90's. Despite its age, the company still has multiple websites that are <a href="https://www.alexa.com/topsites" className={classes.linkText}>Alexa</a> ranked within the top 500 most trafficked websites in the entire world.
            </Typography>
            <Typography variant="body1" align="justify" component="p" className={classes.cvText2}>
            With a Production environment still running on bare metal SGI hardware and a codebase written entirely in Perl CGI-bin, the company provided a unique challenge of bringing old generation systems into the modern era. In my time at FriendFinder I helped develop the Operations Team's roadmap for the Virtualization, Containerization, and Automation of these critical Production systems.
            </Typography>
            <Typography variant="body1" align="justify" component="p" className={classes.cvText2}>
            In addition to day-to-day Site Reliability Engineering, I was responsible for the especially difficult task of creating operation runbooks, documenting decades-old systems rife with spaghetti code, and remediating critical security vulnerabilities like <a href="https://us-cert.cisa.gov/ncas/alerts/TA14-098A" className={classes.linkText}>SSL Heartbleed</a> and <a href="https://nvd.nist.gov/vuln/detail/CVE-2014-6271" className={classes.linkText}>Bash ShellShock</a>. I also served as the Domain Mistress for more than 6,000 of the company's domains.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}