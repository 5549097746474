import AboutMe from '../assets/about.jpg';
import CurrVit from '../assets/cv.jpg';
import SkillsPic from '../assets/skills.jpg';
import FoodPic from '../assets/food.jpg';
import VolPic from '../assets/volunteer.jpg';
import BlogPic from '../assets/writing.jpg';


const pages = [
    {
        title: 'About Me',
        desc: "Mr Poopy Butthole",
        image: AboutMe,
        time: 1500,
    },
    {
        title: 'Curriculum Vitae',
        desc: "What I've done",
        image: CurrVit,
        time: 1500,
    },
    {
        title: 'Skills',
        desc: "What I can do",
        image: SkillsPic,
        time: 1500,
    },
    {
        title: 'Cooking',
        desc: "What I can do",
        image: FoodPic,
        time: 1500,
    },
    {
        title: 'Volunteering',
        desc: "What I can do",
        image: VolPic,
        time: 1500,
    },
    {
        title: 'Writing',
        desc: "What I can do",
        image: BlogPic,
        time: 1500,
    },
];
console.log(pages[0].image);

export default pages;