import React, {useEffect} from 'react';
import { Link as Scroll } from 'react-scroll';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Import Additional Assets


const Styles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
          height: 300,
          fontSize: "3em",
        }
    },
    divider: {
        backgroundColor: '#000',
    },
    headTitle: {
        paddingTop: theme.spacing(4),
        fontFamily: 'Montserrat',
    },
    roundedSquare: {
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff',
    },
    roundedRectangle: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
    },
    profRectangle: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '10rem',
    },
    passvRectangle: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '17.5rem',
    },
    featsSquare: {
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '20rem',
        height: '15rem',
    },
    saveSquare: {
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '12.5rem',
    },
    skillSquare: {
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '12.5rem',
    },
    attackBox: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '20rem',
        height: '12.5rem',
    },
    featBox: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '24.75rem',
        height: '12rem',
    },
    profBox: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '21.5rem',
        height: '12rem',
    },
    graySquare: {
        backgroundColor: '#ddd',
        borderRadius: '10px',
        paddingTop: theme.spacing(1),
    },
    graySquarePad: {
        backgroundColor: '#ddd',
        borderRadius: '10px',
        paddingTop: theme.spacing(1),
    },
    circleBorder: {
        width: '3rem',
        height: '3rem',
        border: '1.5px solid',
        borderRadius: '50%',
        borderColor: '#000',
        backgroundColor: '#fff'
    },
    acBox: {
        width: '6rem',
        height: '6rem',
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff'
    },
    supText: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
    },
    atkText: {
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
    },
    subText: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
        paddingBottom: theme.spacing(1),
    },
    traitSub: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
        paddingTop: theme.spacing(1),
    },
    padTop: {
        paddingTop: theme.spacing(3),
    },
    statBlock: {
        paddingTop: theme.spacing(1),
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
    },
    statText: {
        fontFamily: 'Indie Flower',
        fontSize: '2rem'
    },
    playerText: {
        fontFamily: 'Indie Flower',
        fontSize: '1.5rem'
    },
    classText: {
        fontFamily: 'Indie Flower',
        fontSize: '1.5rem'
    },
    traitText: {
        fontFamily: 'Indie Flower',
        fontSize: '1.1rem'
    },
    atkBox: {
        backgroundColor: '#ddd',
        borderRadius: '10px',
    },
    profBonus: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Indie Flower',
        fontSize: '2rem',
    },
    profText: {
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        fontWeight: 700,
        fontVariant: 'small-caps',
        padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    },
    acText: {
        fontFamily: 'Indie Flower',
        fontSize: '2rem',
        paddingTop: theme.spacing(1),
    },
    acSub: {
        fontFamily: 'Lato',
        fontSize: '0.75rem',
        fontWeight: 700,
        fontVariant: 'small-caps',
        padding: theme.spacing(1, 0, 0, 0),
    },
    skillSave: {
        flex: '1',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Indie Flower',
        fontSize: '1.5rem',
        textDecoration: 'underline',
    },
    subSave: {
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
        padding: theme.spacing(0, 0, 0, 3),
    },
    subSkills: {
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
        padding: theme.spacing(1, 0, 0, 9),
    },
    saveText: {
        fontSize: '0.625rem',
        fontFamily: 'Lato',
        textDecoration: 'none',
        paddingLeft: theme.spacing(1),
    },
    navButton: {
        paddingTop: theme.spacing(3),
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
  }));
  


  export default function AboutHome() {
    const classes = Styles();
    useEffect(() => {
        window.scrollTo(0, 0);
      });

    return (
      <div id="aboutHome" className={classes.root}>
        <Container maxWidth="lg">
            <Divider className={classes.divider} />
            <Grid container direction="row"  justify="space-evenly"  className={classes.padTop}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={2} className={classes.graySquarePad}>
                        <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                            <Typography align="center" className={classes.playerText}>
                                Katemonster
                            </Typography>
                        </Box>
                        <Typography align="center" className={classes.subText}>
                            character name
                        </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Sorcerer (10), Bard (6)
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Entertainer
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Katelynn Cusanelli
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        {'class & level'}
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        background
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        player name
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        White (Italian)
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Chaotic Good
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        165,000
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        race
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        alignment
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        experience points
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
            </Grid>

            <Grid container direction="row"  justify="space-evenly">
                <Grid item md={1}>
                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>
                        <Paper elevation={2} className={classes.graySquare}>
                            <Grid item>
                                <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                    <Typography className={classes.supText}>
                                        strength
                                    </Typography>
                                    <Typography className={classes.statText}>
                                        8
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                <Typography className={classes.supText}>
                                        dexterity
                                    </Typography>
                                    <Typography className={classes.statText}>
                                        12
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                    <Typography className={classes.supText}>
                                        constitution
                                    </Typography>
                                    <Typography className={classes.statText}>
                                        10
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                    <Typography className={classes.supText}>
                                        intelligence
                                    </Typography>
                                    <Typography className={classes.statText}>
                                        18
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                    <Typography className={classes.supText}>
                                        wisdom
                                    </Typography>
                                    <Typography className={classes.statText}>
                                        14
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" display="block" p={1} m={1} align="center" className={classes.roundedSquare}>
                                    <Typography className={classes.supText}>
                                        charisma
                                    </Typography>
                                    <Typography className={classes.statText}>
                                        20
                                    </Typography>
                                </Box>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                
                <Grid item md={2}>
                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>                    
                        <Grid item>
                            <Box display="flex" justifyContent="left">
                                <Box component="span" display="block" align="center" className={classes.circleBorder}>
                                    <Typography direction="column" align="center" className={classes.profBonus}>
                                        +5
                                    </Typography>
                                </Box>
                                <Box component="span" display="block" align="left" className={classes.profRectangle}>
                                    <Typography direction="column" align="center" className={classes.profText}>
                                        proficiency bonus
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box component="span" display="block" align="center" p={1} m={1} className={classes.saveSquare}>
                                <Grid container direction="column" alignItems="flex-start" justify="space-evenly">
                                <Grid item>
                                    <Box display="flex" alignItems="center" justify="space-evenly">
                                        <Typography>
                                            <RadioButtonUncheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;-1&nbsp;
                                        </Typography>
                                        <Typography align="right" className={classes.saveText}>
                                            Strength
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center" justify="space-evenly">
                                        <Typography>
                                            <RadioButtonUncheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+1&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Dexterity
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonCheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+5&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Constitution
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonUncheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+4&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Intelligence
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonUncheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+2&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Wisdom
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonCheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+10&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Charisma
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={10}>
                                        <Typography align="center" className={classes.subSave}>
                                            saving throws
                                        </Typography>
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box component="span" display="block" align="center" p={1} m={1} className={classes.skillSquare}>
                                <Grid container direction="column" alignItems="flex-start" justify="space-evenly">
                                <Grid item>
                                    <Box display="flex" alignItems="center" justify="space-evenly">
                                        <Typography>
                                            <RadioButtonUncheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+2&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Animal Handling (Wis)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center" justify="space-evenly">
                                        <Typography>
                                            <RadioButtonCheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+9&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Arcana (Int)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonUncheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+4&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            History (Int)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonCheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+12&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Insight (Wis)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonCheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+14&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Investigation (Int)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            <RadioButtonCheckedIcon fontSize="small" />&nbsp;
                                        </Typography>
                                        <Typography className={classes.skillSave}>
                                            &nbsp;+10&nbsp;
                                        </Typography>
                                        <Typography className={classes.saveText}>
                                            Performance (Cha)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item alignItems="center" align="center">
                                    <Box display="flex" alignItems="center">
                                        <Typography align="center" className={classes.subSkills}>
                                            skills
                                        </Typography>
                                    </Box>
                                </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={4}>
                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>
                        <Paper elevation={2} className={classes.graySquare}>                    
                            <Grid item> 
                                <Box display="flex" justifyContent="left" justify="space-evenly">
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Box component="span" display="block" align="center" p={1} m={1} className={classes.acBox}>
                                                <Typography className={classes.statText}>
                                                    16
                                                </Typography>
                                                <Typography direction="column" align="center" className={classes.acSub}>
                                                    armor class
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box component="span" display="block" align="center" p={1} m={1} className={classes.acBox}>
                                                <Typography className={classes.statText}>
                                                    +1
                                                </Typography>
                                                <Typography direction="column" align="center" className={classes.acSub}>
                                                    initiative
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box component="span" display="block" align="center" p={1} m={1} className={classes.acBox}>
                                                <Typography className={classes.statText}>
                                                    30
                                                </Typography>
                                                <Typography direction="column" align="center" className={classes.acSub}>
                                                    speed
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item> 
                                <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedRectangle}>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={4} />
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.statText}>
                                                62
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} />
                                    </Grid>
                                    <Divider />
                                    <Grid container direction="row">
                                        <Grid item md={4}>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                current hit points
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>         
                        <Grid item> 
                            <Box component="span" display="block" p={1} m={1} className={classes.attackBox}>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5}>
                                        <Typography align="left" color="textSecondary" className={classes.atkText}>
                                            name
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography align="center" color="textSecondary" className={classes.atkText}>
                                            atk bonus
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography align="center" color="textSecondary" className={classes.atkText}>
                                            damage/type
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            Vicious Mockery
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            dc 18
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            3d4 Psychic
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            Ale-Dritch Blast
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            +10
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            3d8 Cold
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            Glock 19
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            +6
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            2d6 Piercing
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row">
                                    <Grid item md={12}>
                                        <Typography align="center" className={classes.traitSub}>
                                            {'attacks & spellcasting'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>         
                        <Grid item>
                            <Box display="flex" justifyContent="left">
                                <Box component="span" display="block" align="center" className={classes.circleBorder}>
                                    <Typography direction="column" align="center" className={classes.profBonus}>
                                        15
                                    </Typography>
                                </Box>
                                <Box component="span" display="block" align="left" className={classes.passvRectangle}>
                                    <Typography direction="column" align="center" className={classes.profText}>
                                        passive wisdom (perception)
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>         
                        <Grid item>
                            <Box display="flex" justifyContent="left">
                                <Box component="span" display="block" align="center" className={classes.circleBorder}>
                                    <Typography direction="column" align="center" className={classes.profBonus}>
                                        14
                                    </Typography>
                                </Box>
                                <Box component="span" display="block" align="left" className={classes.passvRectangle}>
                                    <Typography direction="column" align="center" className={classes.profText}>
                                        passive intelligence (investigation)
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item md={4}>
                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>
                        <Paper elevation={2} className={classes.graySquare}>                    
                            <Grid item> 
                                <Box component="span" display="block" align="right" p={1} m={1} className={classes.roundedRectangle}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography align="left" className={classes.traitText}>
                                                I'll settle for nothing less than perfection.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container direction="row">
                                        <Grid item md={4}>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                personality traits
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item> 
                                <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography align="left" className={classes.traitText}>
                                                Compassion. The only way to make a better world is to perform small kindnesses.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container direction="row">
                                        <Grid item md={4}>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                ideals
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item> 
                                <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography align="left" className={classes.traitText}>
                                                I protect those who cannot protect themselves.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container direction="row">
                                        <Grid item md={4}>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                bonds
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item> 
                                <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography align="left" className={classes.traitText}>
                                            I do everything big! Subtlety? I don't know the meaning of subtlety! Oh, that's a problem?
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container direction="row">
                                        <Grid item md={4}>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                flaws
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>         
                        <Grid item> 
                            <Box component="span" display="block" p={1} m={1} className={classes.featBox}>
                                <Grid container direction="row" md={12}>
                                    <Typography align="left" className={classes.traitText}>
                                            By Popular Demand (Background)
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Typography align="left" className={classes.traitText}>
                                            Sorcerous Origin: Aberrant Mind
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Typography align="left" className={classes.traitText}>
                                            Jack of All Trades
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Typography align="left" className={classes.traitText}>
                                            Bard College: College of Satire
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Typography align="left" className={classes.traitText}>
                                            Expertise
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Typography align="left" className={classes.traitText}>
                                            Chef (Feat)
                                    </Typography>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item md={12}>
                                        <Typography align="center" className={classes.traitSub}>
                                            {'features & traits'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid container direction="row"  justify="flex-start">
                <Grid item md={3}>
                    <Grid container direction="column" alignItems="flex-start" justify="flex-start" className={classes.statBlock}>         
                        <Grid item> 
                            <Box component="span" display="block" p={1} m={1} className={classes.profBox}>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5}>
                                        <Typography align="center" color="textSecondary" className={classes.atkText}>
                                            type
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} />
                                    <Grid item md={6}>
                                        <Typography align="center" color="textSecondary" className={classes.atkText}>
                                            proficiency
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            language
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} />
                                    <Grid item md={6} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            common
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            language
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} />
                                    <Grid item md={6} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            python
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            weapon
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} />
                                    <Grid item md={6} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            side arms
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            tool
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} />
                                    <Grid item md={6} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            chef's tools
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" md={12}>
                                    <Grid item md={5} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            tool
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} />
                                    <Grid item md={6} className={classes.atkBox}>
                                        <Typography align="center" className={classes.traitText}>
                                            computers
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item md={12}>
                                        <Typography align="center" className={classes.traitSub}>
                                            {'other proficiencies & languages'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={5}>
                    <Grid container spacing={3} direction="row" align="center" className={classes.navButton}>
                        <Scroll to="animalCompanion" smooth={true}><IconButton>
                            <ExpandMoreIcon fontSize="large"/>
                        </IconButton></Scroll>
                     </Grid>
                </Grid>
                {/* <Grid item md={4}>
                    <Grid container direction="row" alignItems="center" justify="center">
                        <Grid item md={5}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="nerdmode" row>
                                    <FormControlLabel
                                        value="NerdMode"
                                        control ={<Switch size="small" color="secondary" />}
                                        label="Muggle View"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </Container>
    </div>
  );
}