import CLDR from '../assets/cloudera.jpg';
import FFN from '../assets/ffn.png';
import Taos from '../assets/taos.png';
import Flutter from '../assets/flutter.png';
import Tivo from '../assets/tivo.png';
import Intel from '../assets/intel.png';
import NTT from '../assets/ntt-verio.svg';

const employers = [
    {
        title: 'Cloudera',
        desc: "Lorem Ipsum",
        image: CLDR,
        time: 1500,
    },
    {
        title: 'FriendFinder Networks',
        desc: "Lorem Ipsum",
        image: FFN,
        time: 1500,
    },
    {
        title: 'Taos Consulting',
        desc: "Lorem Ipsum",
        image: Taos,
        time: 1500,
    },
    {
        title: 'Flutter Wireless',
        desc: "Lorem Ipsum",
        image: Flutter,
        time: 1500,
    },
    {
        title: 'Tivo',
        desc: "Lorem Ipsum",
        image: Tivo,
        time: 1500,
    },
    {
        title: 'Intel',
        desc: "Lorem Ipsum",
        image: Intel,
        time: 1500,
    },
    {
        title: 'NTT-Verio',
        desc: "Lorem Ipsum",
        image: NTT,
        time: 1500,
    },
];

export default employers;