import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';

// Import Additional Assets
import SkillsBG from '../assets/skills-bg.jpg';

const Styles = makeStyles((theme) => ({
    hero: {
      backgroundImage: `url(${SkillsBG})`,
      height: '300px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: 300,
        fontSize: "3em",
      }
    },
    root: {
        height: '100vh'
    },
    cardGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(8),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    divider: {
        backgroundColor: '#000',
    },
    link: {
        textDecoration: 'none',
    },
    table: {
        minWidth: 650,
    },
    headTitle: {
        paddingTop: theme.spacing(4),
        fontFamily: 'Montserrat',
    },
    listStart: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    listText: {
        fontFamily: 'Lato',
    },
    textBody: {
        fontFamily: 'Lato',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    sectionTitle: {
        position: 'relative',
        top: '50%', 
        transform: `translateY(-50%)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat',
    },
    appbar: {
        background: 'none',
        fontFamily: 'Montserrat'
    },
    appbarTitle: {
        flexGrow: '1',
    },
    appbarWrapper: {
        width: '-100%',
        margin: '0 auto',
    },
    icon: {
        color: "#fff",
        fontSize: '2rem',
    },
  }));
  


  export default function SkillsHome() {
    const classes = Styles();
    const [dense, setDense] = useState(true);
    

    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar} elevation={0}>
            <Toolbar className={classes.appbarTitle}>
            <Scroll to="siteSections" smooth={true}><Link to="/"><IconButton>
                    <HomeIcon className={classes.icon}/>
                </IconButton></Link></Scroll>
            </Toolbar>
        </AppBar>
        <Box className={classes.hero} />
        <Container maxWidth="md" className={classes.cardGrid}>
            <Divider className={classes.divider} />
            <Grid container spacing={3} direction="row">
              <Grid item>
                <Typography variant="body1" align="left" color="textPrimary" component="p" className={classes.textBody}>
                I am a highly organized and detail-oriented technologist and polymath with more than 18 years’ experience in IT Operations, Systems Engineering, and Solutions Architecture managing countless enterprise infrastructure deployments. For the past 6 years I have specialized in the architecture of large scale distributed computing environments utilizing the Apache Hadoop stack with an emphasis on Machine Learning to help enterprises take advantage of Big Data solutions. Here are just a few of the technologies I am comfortable with and have experience working with daily:
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h5" align="left" className={classes.headTitle}>
                        Technical Skills
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <List className={classes.listStart} dense={dense}>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Cloudera CDH, HDP, and CDP
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Cloudera Manager
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache Hadoop (HDFS)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache HBase
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache Hive
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache Impala
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Linux Systems Administration
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Node.js
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    React.js
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Puppet, Chef, and Ansible
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    LAMP/LEMP Stacks
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <List className={classes.listStart} dense={dense}>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache MapReduce / YARN
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache Spark
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache ZooKeeper
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache NiFi
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache Flume
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Apache Kafka
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    MIT Kerberos
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Python / PySpark
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Git version control
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Kubernetes
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" className={classes.listText}>
                                    Docker
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    </div>
  );
}