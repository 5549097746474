// Import React Components
import {React, useState} from 'react';

// Import MUI Elements
import { makeStyles, CssBaseline } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Import Site Components
import SiteHeader from './header';

// Import additional assets
import '@fontsource/montserrat';
import '@fontsource/lato';
import '@fontsource/marck-script';

const Styles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
          height: 300,
          fontSize: "3em",
        }
    },
    divider: {
        backgroundColor: '#000',
    },
    blurbContainer: {
        paddingTop: theme.spacing(3)
      },
    blurbTitle: {
        fontWeight: 800,
        fontFamily: 'Montserrat',
      },      
    blurbSubtitle: {
        fontWeight: 300,
        fontFamily: 'Lato',
        fontStyle: 'italic',
        paddingBottom: theme.spacing(3)
      },
    cardGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(8),
    },
    headTitle: {
        paddingTop: theme.spacing(4),
        fontFamily: 'Montserrat',
    },
    listStart: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    listText: {
        fontFamily: 'Lato',
    },
    textBody: {
        fontFamily: 'Lato',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

export default function ChangeLog() {
    const classes = Styles();
    const [dense, setDense] = useState(true);
    return (
      <div className={classes.root}>
          <CssBaseline />
          <SiteHeader />
          <Container maxWidth="lg">
              <Divider className={classes.divider} />
              <Grid container spacing={3} direction="row" className={classes.blurbContainer}>
                <Grid item>
                  <Typography variant="h4" align="left" className={classes.blurbTitle}>
                    Changelog for k8mnstr.com
                  </Typography>
                  <Typography variant="subtitle2" align="left" className={classes.blurbSubtitle}>
                    Version 5.0.0
                  </Typography>
                </Grid>
              </Grid>
              <Divider className={classes.divider}/>
              <Grid container direction="row" spacing={4} className={classes.cardGrid}>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h5" align="left" className={classes.headTitle}>
                            v5.0.0
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h5" align="left" className={classes.headTitle}>
                            Changed
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <List className={classes.listStart} dense={dense}>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Converted site from Bootstrap over to Material UI, React, and Node
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Added Changelog
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Added Attributions page
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Added Skills page
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Added CV page
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Updated About page
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" className={classes.listText}>
                                        Optimized images for loading
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            
                        </List>
                        </Grid>
                </Grid>
          </Container>
      </div>
    );
  }