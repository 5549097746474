// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';

// Import Site Components
import SiteHeader from './components/header';
import CVHome from './components/cvHome';

// Import Additional Assets
import '@fontsource/montserrat';
import '@fontsource/lato';

const Styles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
}));

export default function CVPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <SiteHeader />
      <CVHome />
    </div>
  );
}