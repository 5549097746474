// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

const Styles = makeStyles({
  root: {
    width: '300px',
    margin: '20px',
  },
  media: {
    height: '300px',
    paddingTop: '56.25%', //16:9 ratio
  },
  title: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      textAlign: 'center',
      verticalAlign: 'center',
  },
  desc: {
      fontFamily: 'Lato',
      fontSize: '1.1rem',
  }
});

export default function ImageCard({ page, checked }) {
  const classes = Styles();

  return (
      <Grow in={checked} style={{ transformOrigin: '0 0 0' }} {...(checked ? {timeout: 2500 } : {})}>
        <Card className={classes.root}>
            <CardMedia
            className={classes.media}
            image={page.image}
            title={page.title}
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {page.title}
            </Typography>
            </CardContent>
        </Card>
    </Grow>
  );
}