// Import React Components
import React from 'react';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { useEffect, useState } from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';

// Import Site Components


const Style = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100vh',
    },
    appbar: {
        background: 'none',
        fontFamily: 'Montserrat'
    },
    appbarTitle: {
        flexGrow: '1',
        color: '#000',
    },
    appbarWrapper: {
        width: '-100%',
        margin: '0 auto',
    },
    icon: {
        color: "#000",
        fontSize: '2rem',
    },
    colorText: {
        color: "#FFC0CB",
    }
}));

export default function SiteHeader() {
    const classes = Style();
    const [checked,setChecked] = useState(false);
    useEffect(()=>{
        setChecked(true);
    },[])
    return (
        <div id="header">
            <AppBar className={classes.appbar} elevation={0}>
                <Toolbar className={classes.appbarTitle}>
                <Scroll to="mainPage" smooth={true}><Link to="/"><IconButton>
                        <HomeIcon className={classes.icon}/>
                    </IconButton></Link></Scroll>
                </Toolbar>
            </AppBar>
        </div>
    );
}