// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

// Import Site Components
import CVHeader from './cvHeader';

// Import Additional Assets
import CLDR from '../assets/cloudera.jpg';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e26429',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url(${CLDR})`,
    height: '200px',
    width: '200px',
    margin: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#fff',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff'
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff',
    paddingTop: theme.spacing(1),
  },
  divider: {
    background: '#fff',
  }
}));

export default function CLDRPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CVHeader />
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              Cloudera
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              Get. Shit. Done.
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              Dedicated Systems Engineer
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              2015 - Current (6 years)
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText}>
            If you are familiar with the term "Big Data" then you know what Cloudera is about. Whether your business use case is ingesting, curating, and analyzing real-time streaming data from Edge to Cloud, building and scaling Machine Learning applications, or you are just interested in traditional data warehousing, Cloudera has you covered.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            As a Dedicated Systems Engineer within Cloudera's Premiere organization, I'm responsible for making sure our largest and most important clients are successful in their deployments. This means leading a team of other engineers to provide Solutions Architecture, configuration tuning, cluster and application optimization, and pushing the boundaries of what is possible when operating with truly massive amounts of data at scale. Everything from small proof of concept 10 node clusters, to full scale 3,000+ node Production environments processing upwards of 100 petabytes of data, and anything inbetween.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            Here at Cloudera I'm able to take my more than 18 years of industry experience to help our clients make a real impact, from Healthcare and BioTech companies that are looking to drive better health outcomes, to Financial Institutions that aim to reduce fraud and risk exposure, to other global tech companies that are building a better tomorrow. There's a reason why my team's motto is <em>"Get. Shit. Done."</em>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}