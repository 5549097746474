// Import React Components
import React, {useState, UseEffect} from 'react';
import { Link, useParams } from 'react-router-dom';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// Import Site Components

// Import Additional Assets
import CVCard from './cvCards';
import employer from '../contexts/employers';
import CVBG from '../assets/cv-cover.jpg';

const Styles = makeStyles((theme) => ({
    hero: {
      backgroundImage: `url(${CVBG})`,
      height: '300px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: 300,
        fontSize: "3em",
      }
    },
    root: {
        height: '100vh'
    },
    cardGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(8),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    divider: {
        backgroundColor: '#000',
    },
    link: {
        textDecoration: 'none',
    }
  }));
  
  export default function CVHome() {
    const classes = Styles();

    return (
      <div className={classes.root}>
        <Box className={classes.hero} />
        <Container maxWidth="md" className={classes.cardGrid}>
            <Divider className={classes.divider} />
            <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/cldr" className={classes.link}><CVCard page={employer[0]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/ffn" className={classes.link}><CVCard page={employer[1]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/taos" className={classes.link}><CVCard page={employer[2]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/flutter" className={classes.link}><CVCard page={employer[3]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/tivo" className={classes.link}><CVCard page={employer[4]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/intel" className={classes.link}><CVCard page={employer[5]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4} />
                <Grid item xs={12} sm={6} md={4}>
                <Link to="/cv/verio" className={classes.link}><CVCard page={employer[6]}/></Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4} />
            </Grid>
            {/* <Divider className={classes.divider}/> */}
        </Container>
      </div>
    );
  }