// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

// Import Site Components
import CVHeader from './cvHeader';

// Import Additional Assets
import Flutter from '../assets/flutter.png';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0dafbf',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url(${Flutter})`,
    height: '200px',
    width: '200px',
    margin: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#fff',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff',
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff',
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: '#fff',
  },
  linkText: {
    fontWeight: 700,
    color: '#fff',
    textDecoration: 'none',
  },
}));

export default function FlutterPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CVHeader />
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              Flutter Wireless
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              All the power of Arduino with over 1km range.
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              Co-Founder
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              2013 - 2015 (2 years)
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText}>
            In March of 2013 I co-founded Flutter Wireless, a small electronics startup located in Silicon Valley, California. Flutter is a 100% Open Source microcontroller based on Arduino with an onboard radio allowing it to transmit at a data rate between 30 Kbps to 1.2 Mbps at distances up to and beyond 1 kilometer. In September of 2013 we successfully completed a campaign on <a href="https://www.kickstarter.com/projects/flutterwireless/flutter-20-wireless-arduino-with-half-mile-1km-ran" className={classes.linkText}>Kickstarter</a>, which allowed us to move forward on the project and bring a limited run of our product to market. You can find all of our source files as well as some examples on how to use Flutter on the project's <a href="https://github.com/flutterwireless" className={classes.linkText}>GitHub</a> page.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            As a co-founder I was responsible for all creative media development and community management for over 1,600 domestic and international customers. I was also responsible for successfully managing the Flutter Wireless Kickstarter campaign which exceeded its $80,000 funding goal by 90%, raising $150,000 in capital for the startup. Additionally, I wrote all web, social, and Kickstarter campaign copy and updates. I managed all email communication with backers, handled post-campaign payment processing, and oversaw the campaign migration to BackerKit. I was also responsible for managing press relations and getting us featured in Engadget, TechCrunch, Make, Geek, GeekBoy.it, Quartz, GigaOM, Hacker News, as well as managing our Reddit AMAs.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}