// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

// Import Site Components
import CVHeader from './cvHeader';

// Import Additional Assets
import Tivo from '../assets/tivo-alt.png';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3be33b',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url(${Tivo})`,
    height: '200px',
    width: '200px',
    margin: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#000',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#000',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#000',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#000'
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#000',
    paddingTop: theme.spacing(1),
  },
  divider: {
    background: '#000',
  }
}));

export default function TivoPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CVHeader />
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              TiVo
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              TV Your way.
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              Linux Systems Administrator
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              2012 - 2013 (1 year)
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText}>
            The TiVo brand is iconic and revolutionized the relationship people had with the media they consumed. The echoes of TiVo's impact continue to reverberate through the world today where VODs (Video On Demand) is now the standard for how we interact with content. As the consumer market shifted towards streaming, TiVo stayed relevant by partnering with companies in other Geos where they remain a dominant player in those markets.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            As a Linux Admin for TiVo I had a wide range of responsibilities from Site Reliability Engineering to Network Operations to implementing new monitoring systems to improve visibility into Production systems and consumer settop device performance.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}