// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles, CssBaseline } from '@material-ui/core';

// Import Site Components
import SiteHeader from './components/header';
import MainPage from './components/siteHome';
import PageTwo from './components/siteSections';

// Import Additional Assets
import '@fontsource/montserrat';
import '@fontsource/lato';

const Styles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
}));

export default function HomePage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <SiteHeader />
      <MainPage />
      <PageTwo />
    </div>
  );
}