// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

// Import Site Components
import CVHeader from './cvHeader';

// Import Additional Assets
import Intel from '../assets/intel.png';

const Styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0071c5',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
  },
  logo: {
    backgroundImage: `url(${Intel})`,
    height: '200px',
    width: '200px',
    margin: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '50%', 
    transform: `translateY(-50%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cvTitle: {
    fontWeight: 800,
    fontFamily: 'Montserrat',
    color: '#fff',
    paddingTop: theme.spacing(3),
  },
  cvSubtitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvSubtitle2: {
    fontWeight: 500,
    fontFamily: 'Lato',
    paddingBottom: theme.spacing(3),
    color: '#fff',
  },
  cvText: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff'
  },
  cvText2: {
    fontWeight: 100,
    fontFamily: 'Lato',
    color: '#fff',
    paddingTop: theme.spacing(1),
  },
  divider: {
    background: '#fff',
  }
}));

export default function IntelPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CVHeader />
      <Container maxWidth="md" className={classes.cvGrid}>
        <Divider className={classes.divider} />
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" align="left" className={classes.cvTitle}>
              Intel
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvSubtitle}>
              Creating world-changing technology that enriches the lives of every person on earth.
            </Typography>
            <Typography variant="subtitle2" align="left" className={classes.cvText}>
              Network Validation Engineer
            </Typography>
            <Typography variant="subtitle2" align="left" component="p" className={classes.cvSubtitle2}>
              2010 - 2012 (2 years)
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText}>
            So much of my career up to this point was spent in Linux Systems Administration, so stepping into the world of Validation Engineering really helped me round out my full stack experience by getting hands on at the lowest level. Working in the Lan Access Division based out of the company's Jones Farm campus in Hillsboro, Oregon, I was able to leverage my experience as a Linux Engineer to help validate the latest NIC architectures on industry standard server and network hardware.
            </Typography>
            <Typography variant="body1" align="justify" color="textPrimary" component="p" className={classes.cvText2}>
            My day-to-day responsibilities involved debugging and upgrading NIC EEPROMs, configuring network hardware from Cisco, Foundry Networks, Extreme Networks, Arista, and Dell for VLAN, trunking, flow control, FCoE/iSCSI, 10/40GB bonding, and Data Center Bridging (DCB) use cases, as well as high performance throughput testing using IOMeter, netperf/iperf, Ixia Chariot, and  Spirent Test Center. This included working with rack mount and blade server hardware from vendors such as Dell, HP, Cisco, Sun, Lenovo, and Fujitsu, and troubleshooting, configuring, and updating Dell CMC and HP Virtual Connect Manager for these blade servers.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}