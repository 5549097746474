import React from 'react';
import { Link as Scroll } from 'react-scroll';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// Import Additional Assets
import Pupper from '../assets/Inara.jpg';

const Styles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
          height: 300,
          fontSize: "3em",
        }
    },
    divider: {
        backgroundColor: '#000',
    },
    pupper: {
        backgroundImage: `url(${Pupper})`,
        height: '400px',
        width: '400px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1.5px solid',
        borderRadius: '15px',
    },
    roundedSquare: {
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '5rem',
        height: '5rem',
    },
    roundedRectangle: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
    },
    profRectangle: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '10rem',
    },
    featBox: {
        border: '1.5px solid',
        borderRadius: '10px',
        borderColor: '#000',
        backgroundColor: '#fff',
        width: '24.75rem',
        height: '12rem',
    },
    graySquare: {
        backgroundColor: '#ddd',
        borderRadius: '10px',
        paddingTop: theme.spacing(1),
    },
    graySquarePad: {
        backgroundColor: '#ddd',
        borderRadius: '10px',
        paddingTop: theme.spacing(1),
    },
    acBox: {
        width: '42rem',
        height: '9.5rem',
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff'
    },
    acBox2: {
        width: '42rem',
        height: '9rem',
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff'
    },
    acBox3: {
        width: '42rem',
        height: '11.25rem',
        border: '1.5px solid',
        borderRadius: '15px',
        borderColor: '#000',
        backgroundColor: '#fff'
    },
    supText: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
    },
    subText: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
        paddingBottom: theme.spacing(1),
    },
    traitSub: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: '0.75rem',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontVariant: 'small-caps',
        paddingTop: theme.spacing(1),
    },
    padTop: {
        paddingTop: theme.spacing(3),
    },
    statBlock: {
        paddingTop: theme.spacing(1),
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
    },
    statText: {
        fontFamily: 'Indie Flower',
        fontSize: '2rem'
    },
    playerText: {
        fontFamily: 'Indie Flower',
        fontSize: '1.5rem'
    },
    classText: {
        fontFamily: 'Indie Flower',
        fontSize: '1.5rem'
    },
    traitText: {
        fontFamily: 'Indie Flower',
        fontSize: '1.1rem'
    },
    textTitle: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        fontWeight: 700,
    },
    textTitle2: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        fontWeight: 700,
        paddingTop: theme.spacing(1),
    },
    textBody: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        paddingLeft: theme.spacing(2),
    },
    textBody2: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        paddingLeft: theme.spacing(4),
    },
    textBody3: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        paddingLeft: theme.spacing(7),
    },
    textBody4: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        paddingLeft: theme.spacing(8),
    },
    textBody5: {
        fontFamily: 'Lato',
        fontSize: '0.8rem',
        paddingLeft: theme.spacing(7),
    },
    navButton: {
        paddingTop: theme.spacing(3),
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
  }));
  


  export default function AnimalComp() {
    const classes = Styles();
    

    return (
      <div id="animalCompanion" className={classes.root}>
        <Container maxWidth="lg">
            <Divider className={classes.divider} />
            <Grid container direction="row"  justify="space-evenly"  className={classes.padTop}>
                <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={2} className={classes.graySquarePad}>
                        <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                            <Typography align="center" className={classes.playerText}>
                                Inara
                            </Typography>
                        </Box>
                        <Typography align="center" className={classes.subText}>
                            companion name
                        </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box component="span" display="block" align="left" p={1} m={1} className={classes.roundedRectangle}>
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        3
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Beast
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Medium
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        level
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        type
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        size
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Border Collie
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Lawful Good Girl
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.classText}>
                                        Female
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction="row">
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        breed
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        alignment
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="center" className={classes.subText}>
                                        sex
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Grid container spacing={3} direction="row" align="center" className={classes.navButton}>
                            <Scroll to="aboutHome" smooth={true}><IconButton>
                                <ExpandLessIcon fontSize="large"/>
                            </IconButton></Scroll>
                        </Grid>
                    </Grid>
            </Grid>

            <Grid container direction="row"  align="flex-start" className={classes.padTop} md={12}>
                <Grid container direction="column" justify="flex-start" alignItems="left" md={7}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-evenly">
                        <Grid item>
                            <Box component="span" display="block" p={1} m={1} className={classes.acBox}>
                                <Grid container direction="column" alignItems="flex-start" justify="left">
                                    <Grid item md={12}> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Armor Class:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    13 (Natural Armor)
                                            </Typography>
                                        </Grid> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Hit Points:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    11 (2d8 + 2)
                                            </Typography>
                                        </Grid> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Speed:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    40 ft.
                                            </Typography>
                                        </Grid> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Skills:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    Perception +3, Stealth +4
                                            </Typography>
                                        </Grid> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Senses:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    Passive Perception 13
                                            </Typography>
                                        </Grid> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Languages:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    Understands Common, 普通话, and 日本語
                                            </Typography>
                                        </Grid> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    Proficiency Bonus:
                                            </Typography>
                                            <Typography align="center" className={classes.textBody}>
                                                    +2
                                            </Typography>
                                        </Grid>
                                    </Grid>                                    
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" justify="flex-start" alignItems="center">
                                <Paper elevation={2} className={classes.graySquare}>
                                    <Grid container spacing={2}>
                                    <Grid item>
                                        <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                            <Typography className={classes.supText}>
                                                strength
                                            </Typography>
                                            <Typography className={classes.statText}>
                                                12
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                        <Typography className={classes.supText}>
                                                dexterity
                                            </Typography>
                                            <Typography className={classes.statText}>
                                                15
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                            <Typography className={classes.supText}>
                                                constitution
                                            </Typography>
                                            <Typography className={classes.statText}>
                                                12
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                            <Typography className={classes.supText}>
                                                intelligence
                                            </Typography>
                                            <Typography className={classes.statText}>
                                                6
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="span" display="block" align="center" p={1} m={1} className={classes.roundedSquare}>
                                            <Typography className={classes.supText}>
                                                wisdom
                                            </Typography>
                                            <Typography className={classes.statText}>
                                                12
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="span" display="block" p={1} m={1} align="center" className={classes.roundedSquare}>
                                            <Typography className={classes.supText}>
                                                charisma
                                            </Typography>
                                            <Typography className={classes.statText}>
                                                14
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Box component="span" display="block" p={1} m={1} className={classes.acBox2}>
                                <Grid container direction="column" alignItems="flex-start" justify="left">
                                    <Grid item md={12}> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    <em>Keen Hearing and Smell.</em>
                                            </Typography>
                                            <Typography align="left" className={classes.textBody}>
                                                    Inara has advantage on Wisdom (Perception) checks that rely on hearing (especially for phrases including the words "walk" or "hike") or smell.
                                            </Typography>
                                        </Grid> 
                                    </Grid>
                                    <Grid item md={12}> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle2}>
                                                    <em>Pack Tactics.</em>
                                            </Typography>
                                            <Typography align="left" className={classes.textBody}>
                                                    Inara has advantage on attack rolls against any ankle if at least one of her allies is within 5 feet of the house guest.
                                            </Typography>
                                        </Grid> 
                                    </Grid>
                                    <Grid container direction="row">
                                        <Grid item md={4} />
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                {'abilities'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} />
                                    </Grid>                                       
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box component="span" display="block" p={1} m={1} className={classes.acBox3}>
                                <Grid container direction="row" alignItems="flex-start" justify="left">
                                    <Grid item md={12}> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle}>
                                                    <em>Zoomie Rush.</em>
                                            </Typography>
                                            <Typography align="left" className={classes.textBody}>
                                                    Once per short rest, Inara has the ability to move at triple her movement speed for up to 10 minutes. While under the effects of <em>Zoomie Rush</em>, Inara gains a +2 bonus to her Dexterity and advantage on Initiative rolls.
                                            </Typography>
                                        </Grid> 
                                    </Grid>
                                    <Grid item md={12}> 
                                        <Grid container direction="row" md={12}>
                                            <Typography align="left" className={classes.textTitle2}>
                                                    <em>No Give, Only Throw.</em>
                                            </Typography>
                                            <Typography align="left" className={classes.textBody}>
                                                    <em>Melee Weapon Attack:</em> +5 to hit, reach 5 ft., one target. <em>Hit:</em>  2d4 + 2 psychic damage. If the target is a creature, it must succeed on a DC 11 Wisdom saving throw or become confused. If the attack is successful, target creature has disadvantage against all actions that target Inara until the start of her next turn.
                                            </Typography>
                                        </Grid> 
                                    </Grid>
                                    <Grid container direction="row">
                                        <Grid item md={4} />
                                        <Grid item md={4}>
                                            <Typography align="center" className={classes.traitSub}>
                                                {'attacks & actions'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} />
                                    </Grid>                                 
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container direction="column" justify="flex-start" alignItems="center" md={5}>
                    <Box className={classes.pupper} />
                    <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="space-evenly" className={classes.statBlock}>         
                            <Grid item> 
                                <Box component="span" display="block" p={1} m={1} className={classes.featBox}>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={6}>
                                        <Typography align="left" className={classes.textBody}>
                                                Come
                                        </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography align="center" className={classes.textBody}>
                                                "Go Make Friends!"
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={6}>
                                        <Typography align="left" className={classes.textBody}>
                                                Sit
                                        </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography align="center" className={classes.textBody}>
                                                "Stick 'em up!"
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={6}>
                                        <Typography align="left" className={classes.textBody}>
                                                Paw
                                        </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography align="center" className={classes.textBody}>
                                                Play Dead
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={6}>
                                        <Typography align="left" className={classes.textBody}>
                                                Stay
                                        </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography align="center" className={classes.textBody}>
                                                Roll over
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={6}>
                                        <Typography align="left" className={classes.textBody}>
                                                Fetch
                                        </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography align="center" className={classes.textBody}>
                                                Protecc
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Grid item md={6}>
                                        <Typography align="left" className={classes.textBody}>
                                                Down
                                        </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Typography align="center" className={classes.textBody}>
                                                Attacc
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Typography align="left" className={classes.textBody}>
                                                Guard
                                        </Typography>
                                    </Grid>
                                    <Grid container direction="row" md={12}>
                                        <Typography align="left" className={classes.textBody}>
                                                Left / Right
                                        </Typography>
                                    </Grid>
                                    <Grid container direction="row">
                                        <Grid item md={12}>
                                            <Typography align="center" className={classes.traitSub}>
                                                {'skills & tricks'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

                

            
        </Container>
    </div>
  );
}