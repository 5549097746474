// Import React Components
import { React, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as Scroll, Events } from 'react-scroll';

// Import MUI Elements
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';

// Import Site Components
import ImageCard from './imageCard';
import pages from '../contexts/pages';
import useWindowPosition from '../hook/useWindowPosition';

// Assets


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" text-decoration="none" href="http://k8mnstr.com/">
                k8mnstr.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Styles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#FFC0CB',
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
      },
    link: {
        textDecoration: 'none',
        color: '#000',
    },
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
    },
    navButton: {
        paddingBottom: theme.spacing(10),
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        background: '#000',
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(30),
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
    },
  }))
  


  export default function PageTwo() {
    const classes = Styles();
    const checked = useWindowPosition('header');
    useEffect(() => {
        window.scrollTo(0, 0);
      });

    // const [display, setDisplay] = React.useState(false);
    
    // Events.scrollEvent.register('end', function(to, element) {
    //     setDisplay(true);
    //   });

    

    // TODO - BUGSQUASH - Figure out transition latency issue
  
    return (
            <div className={classes.root} id="siteSections">
                <Container maxWidth="lg" className={classes.cardGrid}>
                    <Grid container spacing={3} direction="row" align="center" className={classes.navButton}>
                        <Scroll to="mainPage" smooth={true}><IconButton>
                            <ExpandLessIcon fontSize="large"/>
                        </IconButton></Scroll>
                    </Grid>
                    <Divider className={classes.divider} />
                    {/* {display &&
                    <> */}
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Link to="/about" className={classes.link}><ImageCard page={pages[0]} checked={checked}/></Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Link to="/cv" className={classes.link}><ImageCard page={pages[1]} checked={checked}/></Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Link to="/skills" className={classes.link}><ImageCard page={pages[2]} checked={checked}/></Link>
                        </Grid>
                    </Grid>
                    {/* <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Link to="" className={classes.link}><ImageCard page={pages[3]} checked={checked}/></Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Link to="" className={classes.link}><ImageCard page={pages[4]} checked={checked}/></Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Link to="" className={classes.link}><ImageCard page={pages[5]} checked={checked}/></Link>
                        </Grid>
                    </Grid> */}
                    {/* </>
                    } */}

                    <Grid container direction="row" spacing={30} align="center" className={classes.footer} gutterBottom>
                            <Copyright className={classes.link} />
                    </Grid>
                    <Grid container direction="row" spacing={30} align="center" justifyContent="center" justify="center" gutterBottom>
                        <Typography variant="subtitle2" align="center" color="textSecondary" component="p">
                        <Link to="/attr" className={classes.link}>Attributions</Link> | <Link to="/changelog" className={classes.link}>Change Log</Link>
                        </Typography>
                    </Grid>
                </Container>
            </div>
    );
  }

  