// Import React Components
import React from 'react';

// Import MUI Elements
import { makeStyles, CssBaseline } from '@material-ui/core';

// Import Site Components
import SiteHeader from './components/header';
import AboutHome from './components/aboutHome';
import AnimalComp from './components/animalComp';

// Import Additional Assets
import '@fontsource/montserrat';
import '@fontsource/lato';
import '@fontsource/marck-script';
import '@fontsource/indie-flower';

const Styles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
}));

export default function AboutPage() {
  const classes = Styles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <SiteHeader />
      <AboutHome />
      <AnimalComp />
    </div>
  );
}